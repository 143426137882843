import React, { SetStateAction, useEffect, useState } from 'react';

import Pubnub from 'pubnub';

export interface ChatListContextType {
    // Chat list
    chatList: Array<
        Modules.Chat & { isOnline?: boolean } & {
            lastVisitorMessageTime?: string;
            lastOperatorMessageTime?: string;
        }
    >;
    setChatList: React.Dispatch<
        React.SetStateAction<
            Array<
                Modules.Chat & { isOnline?: boolean } & {
                    lastVisitorMessageTime?: string;
                    lastOperatorMessageTime?: string;
                }
            >
        >
    >;
    // Channel meta data
    channelMeta: Array<
        Pubnub.ChannelMetadataObject<{ lastVisitorMessageTime: string; lastOperatorMessageTime: string }>
    >;
    setChannelMeta: React.Dispatch<
        SetStateAction<
            Array<Pubnub.ChannelMetadataObject<{ lastVisitorMessageTime: string; lastOperatorMessageTime: string }>>
        >
    >;
    onlineUserIds: string[];
    setOnlineUserIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ChatListContext = React.createContext<ChatListContextType>({
    chatList: [],
    setChatList: () => {},
    channelMeta: [],
    setChannelMeta: () => {},
    onlineUserIds: [],
    setOnlineUserIds: () => {},
});

const ChatListContextProvider = (props: { children: any }) => {
    const [chatList, setChatList] = useState<
        Array<
            Modules.Chat & { isOnline?: boolean } & {
                lastVisitorMessageTime?: string;
                lastOperatorMessageTime?: string;
            }
        >
    >([]);
    const [channelMeta, setChannelMeta] = useState<
        Array<Pubnub.ChannelMetadataObject<{ lastVisitorMessageTime: string; lastOperatorMessageTime: string }>>
    >([]);
    const [onlineUserIds, setOnlineUserIds] = useState<string[]>([]);

    const chatListContext: ChatListContextType = {
        chatList,
        setChatList,
        channelMeta,
        setChannelMeta,
        onlineUserIds,
        setOnlineUserIds,
    };

    return <ChatListContext.Provider value={chatListContext}>{props.children}</ChatListContext.Provider>;
};

export default ChatListContextProvider;
