import PubNub from 'pubnub';
import store from 'store';

const CHAT_SUB_KEY = process.env.CHAT_SUB_KEY!;
const CHAT_PUB_KEY = process.env.CHAT_PUB_KEY!;

export class Notifier {
    public static notifierClient: PubNub;
    public static subscriberId: string;

    public static unsubscribeAll = () => {
        if (Notifier.notifierClient) Notifier.notifierClient.unsubscribeAll();
    };

    public static getNewConnection = ({
        loggedInUser,
    }: {
        loggedInUser: Required<Pick<Users.User, '_id' | 'chatAuthToken'>> & Users.User;
    }) => {
        const pubnubClient = new PubNub({
            publishKey: CHAT_PUB_KEY,
            subscribeKey: CHAT_SUB_KEY,
            uuid: loggedInUser?._id,
            authKey: loggedInUser.chatAuthToken,
            restore: true,
            autoNetworkDetection: true,
            listenToBrowserNetworkEvents: true,
            keepAliveSettings: {
                timeout: 10000,
            },
        });
        Notifier.notifierClient = pubnubClient;
        Notifier.subscriberId = loggedInUser?._id;
        pubnubClient.unsubscribeAll();
        pubnubClient.subscribe({ channels: [`users.${loggedInUser._id}`] });
        loggedInUser?.event?._id &&
            pubnubClient.subscribe({ channels: [`event-operators.${loggedInUser.event?._id}`] });
        return pubnubClient;
    };

    public static getNotifierClient = () => {
        if (Notifier.notifierClient) return Notifier.notifierClient;
        else {
            const loggedInUser = store.get('user') as Users.User;
            const notifierClient = Notifier.getNewConnection({
                loggedInUser: { ...loggedInUser, _id: loggedInUser._id!, chatAuthToken: loggedInUser.chatAuthToken! },
            });
            Notifier.notifierClient = notifierClient;
            return notifierClient;
        }
    };

    public static setActiveUser = ({
        user,
        setActiveUser,
    }: {
        user: Users.User;
        setActiveUser: React.Dispatch<React.SetStateAction<Users.User | undefined>>;
    }) => {
        const userId = user._id;
        const notifierClient = Notifier.getNewConnection({
            loggedInUser: { _id: userId!, chatAuthToken: user.chatAuthToken! },
        });
        Notifier.notifierClient = notifierClient;
        setActiveUser(user);
    };

    public static setActiveClient = async ({
        client,
        setActiveClient,
    }: {
        client: (Required<Pick<Clients.Client, '_id'>> & Clients.Client) | undefined;
        setActiveClient: React.Dispatch<React.SetStateAction<Clients.Client | undefined>>;
    }) => {
        const notifierClient = Notifier.getNotifierClient();
        const clientId = client?._id;
        const subscribedChannels = (await notifierClient.whereNow({ uuid: Notifier.subscriberId })).channels;
        const clientChannels = subscribedChannels.filter(
            channel => channel.includes('event-operators.') || channel.includes('booth-operators.'),
        );
        notifierClient.unsubscribe({ channels: clientChannels });
        if (!clientId) {
            setActiveClient(undefined);
            return;
        }
        // notifierClient.subscribe({ channels: [`client.${clientId}`] });
        setActiveClient(client);
    };

    public static setActiveEvent = async ({
        event,
        setActiveEvent,
    }: {
        event: (Required<Pick<Events.Event, '_id'>> & Events.Event) | undefined;
        setActiveEvent: React.Dispatch<React.SetStateAction<Events.Event | undefined>>;
    }) => {
        const notifierClient = Notifier.getNotifierClient();
        const eventId = event?._id;
        const subscribedChannels = (await notifierClient.whereNow({ uuid: Notifier.subscriberId })).channels;
        const clientChannels = subscribedChannels.filter(channel => channel.includes('booths.'));
        notifierClient.unsubscribe({ channels: clientChannels });
        if (!eventId) {
            setActiveEvent(undefined);
            return;
        }
        notifierClient.subscribe({ channels: [`event-operators.${eventId}`] });
        setActiveEvent(event);
    };

    public static setActiveBooth = async ({
        booth,
        setActiveBooth,
    }: {
        booth: (Required<Pick<Booths.Booth, '_id'>> & Booths.Booth) | undefined;
        setActiveBooth: React.Dispatch<React.SetStateAction<Booths.Booth | undefined>>;
    }) => {
        const notifierClient = Notifier.getNotifierClient();
        const boothId = booth?._id;
        const subscribedChannels = (await notifierClient.whereNow({ uuid: Notifier.subscriberId })).channels;
        const clientChannels = subscribedChannels.filter(channel => channel.includes('booths.'));
        notifierClient.unsubscribe({ channels: clientChannels });
        if (!booth) {
            setActiveBooth(undefined);
            return;
        }
        notifierClient.subscribe({ channels: [`booth-operators.${boothId}`] });
        setActiveBooth(booth);
    };
}
