import React from 'react';

import ChatListContextProvider from './ChatListContextProvider';
import GlobalContextProvider from './GlobalContextProvider';

const wrapWithProvider = ({ element }: { element: React.ReactChildren }) => (
    <GlobalContextProvider>
        <ChatListContextProvider>{element}</ChatListContextProvider>
    </GlobalContextProvider>
);

export default wrapWithProvider;
