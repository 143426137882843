import axios from 'axios';
import dotenv from 'dotenv';
import moment from 'moment-timezone';
import store from 'store';

dotenv.config();

const API_BASE_URL = process.env.API_URL;

const extractAPIData = (res: any) => {
    if (res?.data) return res.data;
    else return res;
};

// Refresh token
const refreshToken = async ({ refreshToken }: { refreshToken: string }) => {
    const res = await axios.post(
        `${API_BASE_URL}/auth/refresh-token`,
        { refreshToken },
        {
            headers: { 'content-type': 'application/json' },
        },
    );
    return extractAPIData(res) as APIRequester.RefreshTokenResponse;
};

const extractAPIError = (res: any) => {
    if (res?.response?.data) return res.response.data;
    else return res;
};

// Token refresh interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const errorCode = error.response?.data?.code;
        if (errorCode === 'ACCESS_TOKEN_EXPIRED') {
            // Try refreshing token
            const refreshTokenValue = store.get('refreshToken');
            if (refreshTokenValue) {
                return refreshToken({ refreshToken: refreshTokenValue }).then(data => {
                    const { accessToken } = data;
                    store.set('token', accessToken);
                    return axios.request({
                        ...error.config,
                        headers: { ...error.config.headers, Authorization: `Bearer ${accessToken}` },
                    });
                });
            } else return Promise.reject(extractAPIError(error));
        } else return Promise.reject(extractAPIError(error));
    },
);

// Initialise the platform by registering super admin
const init = async (params: { emailId: string; password: string; firstName: string; lastName: string }) => {
    const res = await axios.post(`${API_BASE_URL}/auth/init`, params, {
        headers: { 'Content-Type': 'application/json' },
    });
    return extractAPIData(res);
};

// Login
const login = async (params: { emailId: string; password: string }) => {
    const res = await axios.post(`${API_BASE_URL}/auth/login`, params, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as APIRequester.LoginResponse;
};

// Forgot password
const forgotPassword = async (params: { emailId: string }) => {
    const res = await axios.post(`${API_BASE_URL}/auth/forgot-password`, params, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as boolean;
};

// Reset password
const resetPassword = async (params: { token: string; newPassword: string }) => {
    const res = await axios.post(`${API_BASE_URL}/auth/reset-password`, params, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as boolean;
};

// Verify email
const verifyEmail = async (params: { token: string }) => {
    const res = await axios.post(`${API_BASE_URL}/auth/verify-email`, params, {
        headers: { 'content-type': 'application/json' },
    });
    return extractAPIData(res) as boolean;
};

// Get clients
const getClients = async () => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Clients.Client[];
};

// Get client
const getClient = async (id: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${id}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Clients.Client;
};

// Add client
const addClient = async (params: {
    name: string;
    description?: string;
    adminFirstName: string;
    adminLastName: string;
    adminEmailId: string;
}) => {
    const token = store.get('token');
    const res = await axios.post(`${API_BASE_URL}/clients`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Edit client
const editClient = async (_id: string, params: { name: string; description?: string }) => {
    const token = store.get('token');
    const res = await axios.put(`${API_BASE_URL}/clients/${_id}`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Delete client
const deleteClient = async (clientId: string) => {
    const token = store.get('token');
    const res = await axios.delete(`${API_BASE_URL}/clients/${clientId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Get events
const getEvents = async (clientId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Events.Event[];
};

// Get event
const getEvent = async (clientId: string, eventId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Events.Event;
};

// Create event
const createEvent = async (
    clientId: string,
    params: { name: string; description?: string; staffs?: string[]; startTime?: Date; endTime?: Date },
) => {
    const token = store.get('token');
    const res = await axios.post(`${API_BASE_URL}/clients/${clientId}/events`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Edit event
const editEvent = async (
    clientId: string,
    eventId: string,
    params?: {
        name?: string;
        description?: string;
        staffs?: string[];
        startTime?: Date;
        endTime?: Date;
        profileFields?: any;
        boothTree?: any;
        eventRootAddress?: string;
        enableCaptcha?: boolean;
    },
) => {
    const token = store.get('token');
    const res = await axios.put(`${API_BASE_URL}/clients/${clientId}/events/${eventId}`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Delete event
const deleteEvent = async (clientId: string, eventId: string) => {
    const token = store.get('token');
    const res = await axios.delete(`${API_BASE_URL}/clients/${clientId}/events/${eventId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Get client operators
const getOperators = async (clientId: string, eventId?: string, boothId?: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/users?eventId=${eventId}&boothId=${boothId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Users.User[];
};

// Get event visitors
const getVisitors = async (clientId: string, eventId: string, boothId?: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/users?boothId=${boothId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Users.User[];
};

// Create client user
const createClientUser = async (
    clientId: string,
    params: {
        firstName: string;
        lastName: string;
        emailId: string;
        password?: string;
        role: string;
        mobile?: string;
        designation?: string;
    },
) => {
    const token = store.get('token');
    const res = await axios.post(`${API_BASE_URL}/clients/${clientId}/users`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Edit client user
const editClientUser = async (
    clientId: string,
    userId: string,
    params: {
        firstName: string;
        lastName: string;
        emailId: string;
        role: string;
        mobile?: string;
        designation?: string;
        tncAccepted?: boolean;
        optedForUpdates?: boolean;
        activated?: boolean;
    },
) => {
    const token = store.get('token');
    const res = await axios.put(`${API_BASE_URL}/clients/${clientId}/users/${userId}`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Delete client user
const deleteClientUser = async (clientId: string, userId: string) => {
    const token = store.get('token');
    const res = await axios.delete(`${API_BASE_URL}/clients/${clientId}/users/${userId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Get booths
const getBooths = async (clientId: string, eventId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Booths.Booth[];
};

// Get booth
const getBooth = async (clientId: string, eventId: string, boothId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Booths.Booth;
};

// Create booth
const createBooth = async (
    clientId: string,
    eventId: string,
    params: { name: string; description?: string; staffs?: string[]; sponsors?: string[] },
) => {
    const token = store.get('token');
    const res = await axios.post(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Edit booth
const editBooth = async (
    clientId: string,
    eventId: string,
    boothId: string,
    params: {
        name: string;
        description?: string;
        staffs?: string[];
        sponsors?: string[];
        category?: string;
        showInMannedList?: boolean;
        panoramaName?: string;
        overlayName?: string;
        socialLinks?: string[];
    },
) => {
    const token = store.get('token');
    const res = await axios.put(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Edit booth
const editModule = async (
    clientId: string,
    eventId: string,
    boothId: string,
    moduleId: string,
    params?: { name?: string; subType?: Modules.VIDEO_CALL_MODULE_TYPE },
) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}`,
        params,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Delete booth
const deleteBooth = async (clientId: string, eventId: string, boothId: string) => {
    const token = store.get('token');
    const res = await axios.delete(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Activate event module
const addEventModule = async (clientId: string, eventId: string, params: { type: Modules.MODULE_IDS }) => {
    const token = store.get('token');
    const res = await axios.post(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules`, params, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Fetch event modules
const getEventModules = async (clientId: string, eventId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.Module[];
};

// Fetch event module
const getEventModule = async (clientId: string, eventId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.Module;
};

// Remove event module
const removeEventModule = async (clientId: string, eventId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.delete(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

// Toggle event module
const toggleEventModule = async (clientId: string, eventId: string, moduleId: string, type: boolean) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}`,
        { activated: type },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Update event form module questions
const updateEventFormModuleQuestions = async (clientId: string, eventId: string, moduleId: string, questions: any) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/questions`,
        questions,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Update booth form module questions
const updateBoothFormModuleQuestions = async (
    clientId: string,
    eventId: string,
    boothId: string,
    moduleId: string,
    questions: any,
) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/questions`,
        questions,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Activate booth module
const addBoothModule = async (
    clientId: string,
    eventId: string,
    boothId: string,
    params: { type: Modules.MODULE_IDS },
) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules`,
        params,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Fetch booth modules
const getBoothModules = async (clientId: string, eventId: string, boothId: string): Promise<Array<Modules.Module>> => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Array<Modules.Module>;
};

// Fetch booth module
const getBoothModule = async <T>(
    clientId: string,
    eventId: string,
    boothId: string,
    moduleId: string,
): Promise<Modules.Module<T>> => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Promise<Modules.Module<T>>;
};

// Remove booth module
const removeBoothModule = async (clientId: string, eventId: string, boothId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Toggle booth module
const toggleBoothModule = async (
    clientId: string,
    eventId: string,
    moduleId: string,
    type: boolean,
    boothId: string,
) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}`,
        { activated: type },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Generate file upload url
const getFileUploadUrl = ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/files`;

// Generate file upload url
const getFileThumbnailUploadUrl = ({
    fileId,
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    fileId: string;
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) =>
    `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/files/${fileId}/thumbnail`;

// Get files
const getFiles = async (clientId: string, eventId: string, boothId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/files/`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.File[];
};

// Delete file
const deleteFile = async (clientId: string, eventId: string, boothId: string, moduleId: string, fileId: string) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/files/${fileId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

// Fetch list of online visitors
const getOnlineVisitors = async (clientId: string, eventId: string, boothId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/online-visitors`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Users.User[];
};

const getVideoCallRequests = async (clientId: string, eventId: string, boothId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/operator`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoCallRequest[];
};

const requestVideoCall = async (
    clientId: string,
    eventId: string,
    boothId: string,
    moduleId: string,
    respondentUserId: string,
) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/operator`,
        { respondentUserId },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const mergeVideoCalls = async (
    clientId: string,
    eventId: string,
    boothId: string,
    moduleId: string,
    videoCallRequestIds: string[],
) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/merge`,
        { videoCallRequestIds },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const acceptVideoCallRequest = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/accept/operator`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const createVideoRoom = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
    isGroup = false,
    isBroadcast = false,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
    isGroup: boolean;
    isBroadcast: boolean;
}) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/room`,
        { isGroup, isBroadcast },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoRoom;
};

const addVisitorDirectlyToVideoRoomById = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
    email,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    roomId: string;
    email: string;
}) => {
    const token = store.get('token');
    const tz = moment.tz.guess();
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/book-slot-by-email?tz=${tz}`,
        { email },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
};

const joinVideoRoom = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    roomId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/join`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const endVideoRoom = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    roomId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/end`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const addVisitorToRoom = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
    requestId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    roomId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/add-visitor`,
        { requestId },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const joinVideoCall = async (
    clientId: string,
    eventId: string,
    boothId: string,
    moduleId: string,
    requestId: string,
) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/join`,
        {
            status,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoCallRequest & { requester: Users.User; respondent: Users.User };
};

const createScheduledVideoRoom = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    name,
    isGroup,
    isBroadcast,
    startTime,
    endTime,
    canBeScheduledByVisitor,
    maxVisitorCount,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    name: string;
    isGroup: boolean;
    isBroadcast: boolean;
    startTime: string;
    endTime: string;
    canBeScheduledByVisitor: boolean;
    maxVisitorCount: number;
}) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/scheduled`,
        {
            name,
            isGroup,
            isBroadcast,
            startTime,
            endTime,
            canBeScheduledByVisitor,
            maxVisitorCount,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as { room: Modules.VideoRoom };
};

const viewVideoRooms = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    status = 'active',
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    status?: 'active' | 'ended';
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/operator?status=${status}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.VideoRoom[];
};

const rejectVideoCallRequest = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/reject/operator`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const endVideoCallRequest = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/requests/${requestId}/end/operator`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getMissedCalls = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/missed-calls`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as MissedCall.MissedCall[];
};

const getWebChats = async (clientId: string, eventId: string, boothId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/chats`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.Chat[];
};

const joinChat = async (clientId: string, eventId: string, boothId: string, moduleId: string, chatId: string) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/chats/${chatId}`,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const startChat = async (clientId: string, eventId: string, boothId: string, moduleId: string, visitorId: string) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/chats`,
        { visitorId },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getAuthModuleConfig = async (clientId: string, eventId: string, moduleId: string) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/auth-config`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.AuthModuleData;
};

const saveAuthModuleConfig = async (
    clientId: string,
    eventId: string,
    moduleId: string,
    authConfig: Modules.AuthModuleData,
) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/auth-config`,
        authConfig,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const addBoothCategory = async (clientId: string, eventId: string, name: string) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/categories`,
        { name },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const updateBoothCategory = async (clientId: string, eventId: string, categoryId: string, name: string) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/categories/${categoryId}`,
        { name },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const deleteBoothCategory = async (clientId: string, eventId: string, categoryId: string) => {
    const token = store.get('token');
    const res = await axios.delete(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/categories/${categoryId}`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const getUserExtraProfileFields = async (clientId: string, eventId: string) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/extra-user-profile-fields`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Users.ExtraField[];
};

const createUserExtraProfileField = async (clientId: string, eventId: string, field: Users.ExtraField) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/extra-user-profile-fields`,
        field,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const updateUserExtraProfileField = async (
    clientId: string,
    eventId: string,
    fieldId: string,
    field: Users.ExtraField,
) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/extra-user-profile-fields/${fieldId}`,
        field,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const deleteUserExtraProfileField = async (clientId: string, eventId: string, fieldId: string) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/extra-user-profile-fields/${fieldId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getProfilePictureUploadUrl = ({
    clientId,
    eventId,
    userId,
}: {
    clientId: string;
    eventId: string;
    userId: string;
}) => `${API_BASE_URL}/clients/${clientId}/events/${eventId}/users/${userId}/profile-picture`;

const deleteProfilePicture = async ({
    clientId,
    eventId,
    userId,
}: {
    clientId: string;
    eventId: string;
    userId: string;
}) => {
    const token = store.get('token');
    const res = await axios.delete(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/users/${userId}/profile-picture`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getEventConfig = async ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const token = store.get('token');
    const res = await axios.get(`${API_BASE_URL}/clients/${clientId}/events/${eventId}/config`, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Events.Config['data'];
};

const saveEventConfig = async ({
    clientId,
    eventId,
    data,
}: {
    clientId: string;
    eventId: string;
    data: Events.Config['data'];
}) => {
    const token = store.get('token');
    const res = await axios.put(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/config`,
        {
            ...data,
        },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as string;
};

const findAllVisitorActions = async ({
    clientId,
    eventId,
    visitorId,
}: {
    clientId: string;
    eventId: string;
    visitorId: string;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/visitor-actions?visitorId=${visitorId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as VisitorActions.VisitorAction[];
};

const findAllActionsOfBoothVisitors = async ({
    clientId,
    eventId,
    boothId,
    startDate,
    endDate,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    startDate: string;
    endDate: string;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/visitor-actions?startDate=${startDate}&endDate=${endDate}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as VisitorActions.VisitorAction[];
};

const findAllActionsOfEvent = async ({
    clientId,
    eventId,
    boothId,
    startDate,
    endDate,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
    startDate: string;
    endDate: string;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/visitor-actions/all?startDate=${startDate}&endDate=${endDate}&boothId=${boothId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as VisitorActions.VisitorAction[];
};

const findAllActionsOfBooth = async ({
    clientId,
    eventId,
    boothId,
    startDate,
    endDate,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
    startDate: string;
    endDate: string;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/visitor-actions/all?startDate=${startDate}&endDate=${endDate}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as VisitorActions.VisitorAction[];
};

const findAllVisitorActionsForSponsor = async ({
    clientId,
    eventId,
    boothId,
    visitorId,
}: {
    clientId: string;
    eventId: string;
    visitorId: string;
    boothId: string;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/visitor-actions/sponsor?visitorId=${visitorId}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as VisitorActions.VisitorAction[];
};

const getTotalVisitorCountSummary = async ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/active-inactive-visitor-count`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as EventsAnalytics.TotalVisitorCountSummary;
};

const getStatsForBilling = async ({
    clientId,
    eventId,
    startTime,
    endTime,
}: {
    clientId: string;
    eventId: string;
    startTime: number;
    endTime: number;
}) => {
    const token = store.get('token');
    const res = await axios.get(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/stats-for-billing?startTime=${startTime}&endTime=${endTime}`,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as { totalParticipantMinutes: number; totalUserRegistrations: number };
};

const getOnlineVisitorCount = async ({
    clientId,
    eventId,
    boothId,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
}) => {
    const url = boothId
        ? `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/online-visitors?boothId=${boothId}`
        : `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/online-visitors`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as EventsAnalytics.OnlineVisitorCountSummary;
};

const getOnlineVisitorCountHistory = async ({
    clientId,
    eventId,
    boothId,
    startDate,
    endDate,
    timeZone,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    startDate: string;
    endDate: string;
    timeZone: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/online-visitors-history?boothId=${boothId}&startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as EventsAnalytics.OnlineVisitorCountHistory;
};

const getOnlineVisitorCountHistoryDownload = async ({
    clientId,
    eventId,
    boothId,
    startDate,
    endDate,
    timeZone,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    startDate: string;
    endDate: string;
    timeZone: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/online-visitors-history/download?boothId=${boothId}&startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as VisitorActions.VisitorAction[];
};

const getVideoCallSummary = async ({
    clientId,
    eventId,
    boothId,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
}) => {
    const url = boothId
        ? `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/video-call-summary?boothId=${boothId}`
        : `${API_BASE_URL}/clients/${clientId}/events/${eventId}/analytics/video-call-summary`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as EventsAnalytics.VideoCallSummary;
};

const getLiveStreamQuestions = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    status,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    status?: Modules.LIVE_STREAM_QUESTION_STATUS;
}) => {
    const url = status
        ? `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-questions?status=${status}`
        : `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-questions`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.LiveStreamQuestion[];
};

const approveLiveStreamQuestion = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
    questionId,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
    moduleId?: string;
    roomId: string;
    questionId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-questions/${questionId}/approve`;
    const token = store.get('token');
    const res = await axios.put(
        url,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res);
};

const rejectLiveStreamQuestion = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    questionId,
    rejectReason,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
    moduleId?: string;
    questionId: string;
    rejectReason?: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-questions/${questionId}/reject`;
    const token = store.get('token');
    const res = await axios.put(
        url,
        { rejectReason },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res);
};

const archiveLiveStreamQuestions = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId?: string;
    moduleId?: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-questions/archive`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res);
};

const addSpeakerToLiveStream = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    userId,
    fromTime,
    toTime,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    userId: string;
    fromTime: string;
    toTime: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-speakers`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { userId, fromTime, toTime },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res);
};

const getLiveStreamSpeakers = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-speakers`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.LiveStreamingSpeaker[];
};

const getLiveStreamSpeakRequests = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-requests`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.VideoCallRequest[];
};

const getLiveStreamSpeakRequestsOfOperator = async ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/live-streams/requests`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.VideoCallRequest[];
};

const getLiveStreams = async ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/live-streams/all`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.Module<Modules.LiveStreamingData>[];
};

const acceptLiveStreamSpeaker = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming/requests/${requestId}/accept`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        {},
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const rejectLiveStreamSpeaker = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
    reason,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
    reason?: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming/requests/${requestId}/reject`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { reason },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const deleteLiveStreamSpeakerRequest = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    requestId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    requestId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming/requests/${requestId}`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const deleteLiveStreamSpeaker = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    speakerId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    speakerId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming-speakers/${speakerId}`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const getVideosOfVideoModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/videos`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.VideoModuleVideo[];
};

const getLinkOfLinkModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/links`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.LinkModuleLink[];
};

const addVideoToVideoModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    link,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    link: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/videos`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { link },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const addLinkToLinkModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    link,
    text,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    link: string;
    text?: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/links`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { link, text },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const modifyVideoOfVideoModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    videoId,
    link,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    videoId: string;
    link: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/videos/${videoId}`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { link },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const deleteVideoOfVideoModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    videoId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    videoId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/videos/${videoId}`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const deleteLinkOfLinkModule = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    linkId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    linkId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/links/${linkId}`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const getExtFormModulesResponses = async ({
    clientId,
    eventId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/ext/responses`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.ExtFormModuleResponse[];
};

const saveSiemensFormQuestions = async ({
    clientId,
    eventId,
    moduleId,
    questions,
}: {
    clientId: string;
    eventId: string;
    moduleId: string;
    questions: Modules.SiemensFormQuestionModuleData;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/siemens/questions`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { questions },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getSiemensFormQuestions = async ({
    clientId,
    eventId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/siemens/questions`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.SiemensFormQuestionModuleData['siemensFormQuestions'];
};

// Create visitor account
const createVisitorAccount = async (
    clientId: string,
    eventId: string,
    moduleId: string,
    params: {
        firstName: string;
        lastName: string;
        emailId: string;
        password?: string;
        mobile?: string;
        designation?: string;
    },
) => {
    const token = store.get('token');
    const res = await axios.post(
        `${API_BASE_URL}/clients/${clientId}/events/${eventId}/modules/${moduleId}/register`,
        params,
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getEmailTemplates = async ({ clientId, eventId }: { clientId: string; eventId: string }) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/email-templates`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as EmailTemplates.EmailTemplate[];
};

const deleteEmailTemplate = async ({
    clientId,
    eventId,
    templateId,
}: {
    clientId: string;
    eventId: string;
    templateId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/email-templates/${templateId}`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const createEmailTemplate = async ({
    clientId,
    eventId,
    target,
    type,
    template,
}: {
    clientId: string;
    eventId: string;
    target: EmailTemplates.EMAIL_TEMPLATE_TARGETS;
    type: EmailTemplates.EMAIL_TEMPLATE_TYPE;
    template: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/email-templates`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { target, type, template },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const updateEmailTemplate = async ({
    clientId,
    eventId,
    templateId,
    target,
    type,
    template,
}: {
    clientId: string;
    eventId: string;
    templateId: string;
    target: EmailTemplates.EMAIL_TEMPLATE_TARGETS;
    type: EmailTemplates.EMAIL_TEMPLATE_TYPE;
    template: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/email-templates/${templateId}`;
    const token = store.get('token');
    const res = await axios.put(
        url,
        { target, type, template },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getProducts = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products`;
    const token = store.get('token');
    const products = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(products) as Products.Product[];
};

const createProduct = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    name,
    imageType,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    name: string;
    imageType: Products.PRODUCT_IMAGE_TYPE;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { name, imageType },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const updateProduct = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    productId,
    name,
    imageType,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    productId: string;
    name: string;
    imageType: Products.PRODUCT_IMAGE_TYPE;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products/${productId}`;
    const token = store.get('token');
    const res = await axios.put(
        url,
        { name, imageType },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const deleteProduct = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    productId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    productId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products/${productId}`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as boolean;
};

const updateProductImageIframeLink = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    productId,
    link,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    productId: string;
    link: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products/${productId}/iframe-image`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { link },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getProductImageUploadUrl = ({
    clientId,
    eventId,
    boothId,
    moduleId,
    productId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    productId: string;
}) =>
    `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products/${productId}/direct-image`;

const getProductDocumentUploadUrl = ({
    clientId,
    eventId,
    boothId,
    moduleId,
    productId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    productId: string;
}) =>
    `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/products/${productId}/document`;

const requestToSpeak = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    description,
    fromTime,
    toTime,
    files,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    description: string;
    fromTime: string;
    toTime: string;
    files: string[];
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming/speak-request`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { description, fromTime, toTime, files },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const inviteToBoothViaEmail = async ({
    clientId,
    eventId,
    boothId,
    emails,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    emails: string[];
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/invite-to-booth-via-email`;
    const token = store.get('token');
    const res = await axios.post(
        url,
        { emails },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as boolean;
};

const getLiveStreamRecordings = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/live-streaming/recordings`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.DailyCoRecordings[];
};

const getLiveStreamRecordingsOfRoom = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    roomId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    roomId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/rooms/${roomId}/recordings`;
    const token = store.get('token');
    const res = await axios.get(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.DailyCoRecordings[];
};

const removeFileThumbnail = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    fileId,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    fileId: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/files/${fileId}/thumbnail`;
    const token = store.get('token');
    const res = await axios.delete(url, {
        headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
    });
    return extractAPIData(res) as Modules.DailyCoRecordings[];
};

const renameFile = async ({
    clientId,
    eventId,
    boothId,
    moduleId,
    fileId,
    customName,
}: {
    clientId: string;
    eventId: string;
    boothId: string;
    moduleId: string;
    fileId: string;
    customName: string;
}) => {
    const url = `${API_BASE_URL}/clients/${clientId}/events/${eventId}/booths/${boothId}/modules/${moduleId}/files/${fileId}`;
    const token = store.get('token');
    const res = await axios.put(
        url,
        { customName },
        {
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
        },
    );
    return extractAPIData(res) as Modules.DailyCoRecordings[];
};

export const apiRequester = {
    init,
    login,
    forgotPassword,
    resetPassword,
    verifyEmail,
    getClients,
    getClient,
    addClient,
    editClient,
    deleteClient,
    getEvents,
    getEvent,
    createEvent,
    editEvent,
    deleteEvent,
    getOperators,
    getVisitors,
    createClientUser,
    editClientUser,
    deleteClientUser,
    getBooths,
    getBooth,
    createBooth,
    editBooth,
    deleteBooth,
    addEventModule,
    getEventModules,
    getEventModule,
    removeEventModule,
    toggleEventModule,
    updateEventFormModuleQuestions,
    updateBoothFormModuleQuestions,
    addBoothModule,
    getBoothModules,
    getBoothModule,
    removeBoothModule,
    toggleBoothModule,
    getFileUploadUrl,
    getFileThumbnailUploadUrl,
    deleteFile,
    getOnlineVisitors,
    getVideoCallRequests,
    requestVideoCall,
    acceptVideoCallRequest,
    joinVideoCall,
    endVideoCallRequest,
    rejectVideoCallRequest,
    getWebChats,
    joinChat,
    startChat,
    getAuthModuleConfig,
    saveAuthModuleConfig,
    mergeVideoCalls,
    addBoothCategory,
    updateBoothCategory,
    deleteBoothCategory,
    getUserExtraProfileFields,
    createUserExtraProfileField,
    updateUserExtraProfileField,
    deleteUserExtraProfileField,
    getProfilePictureUploadUrl,
    deleteProfilePicture,
    saveEventConfig,
    getEventConfig,
    createVideoRoom,
    joinVideoRoom,
    endVideoRoom,
    addVisitorToRoom,
    createScheduledVideoRoom,
    viewVideoRooms,
    editModule,
    findAllActionsOfBoothVisitors,
    findAllActionsOfEvent,
    findAllVisitorActions,
    findAllVisitorActionsForSponsor,
    getFiles,
    getTotalVisitorCountSummary,
    getOnlineVisitorCount,
    getOnlineVisitorCountHistory,
    getOnlineVisitorCountHistoryDownload,
    getVideoCallSummary,
    getLiveStreamQuestions,
    approveLiveStreamQuestion,
    rejectLiveStreamQuestion,
    addSpeakerToLiveStream,
    getLiveStreamSpeakers,
    deleteLiveStreamSpeaker,
    getMissedCalls,
    getVideosOfVideoModule,
    addVideoToVideoModule,
    modifyVideoOfVideoModule,
    deleteVideoOfVideoModule,
    getExtFormModulesResponses,
    saveSiemensFormQuestions,
    getSiemensFormQuestions,
    createVisitorAccount,
    addVisitorDirectlyToVideoRoomById,
    findAllActionsOfBooth,
    getEmailTemplates,
    createEmailTemplate,
    updateEmailTemplate,
    deleteEmailTemplate,
    getProducts,
    createProduct,
    updateProduct,
    deleteProduct,
    updateProductImageIframeLink,
    getProductImageUploadUrl,
    getProductDocumentUploadUrl,
    getLiveStreamSpeakRequests,
    getLiveStreamSpeakRequestsOfOperator,
    acceptLiveStreamSpeaker,
    rejectLiveStreamSpeaker,
    deleteLiveStreamSpeakerRequest,
    getLiveStreams,
    requestToSpeak,
    inviteToBoothViaEmail,
    getLiveStreamRecordings,
    getLiveStreamRecordingsOfRoom,
    archiveLiveStreamQuestions,
    getStatsForBilling,
    refreshToken,
    addLinkToLinkModule,
    getLinkOfLinkModule,
    deleteLinkOfLinkModule,
    removeFileThumbnail,
    renameFile,
};
