import React, { useEffect, useState } from 'react';

import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';

import { apiRequester, Notifier } from '../utility';

export interface GlobalContextType {
    user?: Users.User;
    changeUser: (user: Users.User) => void;
    token?: string;
    changeToken?: (token?: string) => void;
    // Client
    clients: Clients.Client[];
    activeClient?: Clients.Client | undefined;
    setClients: (clients: Clients.Client[]) => void;
    setActiveClient: (client: (Required<Pick<Clients.Client, '_id'>> & Clients.Client) | undefined) => void;
    refreshClients: () => Promise<Clients.Client[]>;
    // Event
    activeEvent?: Events.Event | undefined;
    events: Events.Event[];
    setEvents: (events: Events.Event[]) => void;
    setActiveEvent: (event: (Required<Pick<Events.Event, '_id'>> & Events.Event) | undefined) => void;
    refreshEvents: (clientId: string) => Promise<Events.Event[]>;
    refreshActiveEvent: (clientId: string, eventId: string) => Promise<Events.Event>;
    // Client user
    clientUsers: Users.User[];
    setClientUsers: (users: Users.User[]) => void;
    refreshClientUsers: (clientId: string, eventId?: string, boothId?: string) => Promise<Users.User[]>;
    // Booth
    booths: Booths.Booth[];
    activeBooth?: Booths.Booth | undefined;
    setBooths: (booths: Booths.Booth[]) => void;
    setActiveBooth: (booth: (Required<Pick<Booths.Booth, '_id'>> & Booths.Booth) | undefined) => void;
    refreshBooths: (clientId: string, eventId: string) => Promise<Booths.Booth[]>;
    // Event module
    eventModules: Modules.Module[];
    setEventModules: (events: Modules.Module[]) => void;
    refreshEventModules: (clientId: string, eventId: string) => Promise<Modules.Module[]>;
    // Booth module
    boothModules: Modules.Module[];
    setBoothModules: (events: Modules.Module[]) => void;
    refreshBoothModules: (clientId: string, eventId: string, boothId: string) => Promise<Modules.Module[]>;
    // Notifier
    unsubscribeAllFromNotifier: () => void;
    // User List
    userList: { [id: string]: Users.User };
    refreshUserList: (userIds: string[]) => Promise<{ [id: string]: Users.User }>;
    // Room
    activeVideoRoom?: Modules.VideoRoom;
    setActiveVideoRoom: (room: Modules.VideoRoom | undefined) => void;
    // Global Pubnub
    pubnub?: Pubnub;
    setPubnub: (pubnub: Pubnub) => void;
    channels: string[];
    oldChannels: string[];
    setChannels: React.Dispatch<React.SetStateAction<string[]>>;
    setOldChannels: React.Dispatch<React.SetStateAction<string[]>>;
}

export const GlobalContext = React.createContext<GlobalContextType>({
    user: undefined,
    changeUser: () => {},
    activeClient: undefined,
    clients: [],
    setClients: () => {},
    setActiveClient: () => {},
    refreshClients: async () => [],
    activeEvent: undefined,
    events: [],
    setEvents: () => {},
    setActiveEvent: () => {},
    refreshEvents: async () => [],
    refreshActiveEvent: () => Promise.resolve({}),
    clientUsers: [],
    setClientUsers: () => {},
    refreshClientUsers: async () => [],
    booths: [],
    activeBooth: undefined,
    setBooths: () => {},
    setActiveBooth: () => {},
    refreshBooths: async () => [],
    eventModules: [],
    setEventModules: () => {},
    refreshEventModules: async () => [],
    boothModules: [],
    setBoothModules: () => {},
    refreshBoothModules: async () => [],
    unsubscribeAllFromNotifier: Notifier.unsubscribeAll,
    userList: {},
    refreshUserList: () => Promise.resolve({}),
    setActiveVideoRoom: () => {},
    setPubnub: () => {},
    channels: [],
    oldChannels: [],
    setChannels: () => ([] as never) as React.Dispatch<React.SetStateAction<string[]>>,
    setOldChannels: () => ([] as never) as React.Dispatch<React.SetStateAction<string[]>>,
});

const GlobalContextProvider = (props: { children: any }) => {
    const [user, changeUser] = useState<Users.User>();
    const [token, changeToken] = useState<string>();
    const [clients, setClients] = useState<Clients.Client[]>([]);
    const [activeClient, setActiveClient] = useState<Clients.Client | undefined>(undefined);
    const [events, setEvents] = useState<Events.Event[]>([]);
    const [activeEvent, setActiveEvent] = useState<Events.Event | undefined>(undefined);
    const [clientUsers, setClientUsers] = useState<Users.User[]>([]);
    const [booths, setBooths] = useState<Booths.Booth[]>([]);
    const [activeBooth, setActiveBooth] = useState<Booths.Booth | undefined>(undefined);
    const [eventModules, setEventModules] = useState<Modules.Module[]>([]);
    const [boothModules, setBoothModules] = useState<Modules.Module[]>([]);
    const [userList, setUserList] = useState<{ [id: string]: Users.User }>({});
    const [channels, setChannels] = useState<string[]>([]);
    const [oldChannels, setOldChannels] = useState<string[]>([]);
    const [activeVideoRoom, setActiveVideoRoom] = useState<Modules.VideoRoom>();
    const [pubnub, setPubnub] = useState<Pubnub>();

    useEffect(() => {
        if (channels && channels.length) {
            console.info('Subscribing to channels', channels);
            pubnub?.subscribe({ channels, withPresence: true });
        }

        const x = setInterval(() => {
            const userId = user?._id;
            if (userId) {
                pubnub?.whereNow({ uuid: userId }).then(response => {
                    const subscribedChannels = response.channels;
                    console.log({
                        currentlySubscribedBoot: subscribedChannels,
                        boothsToActuallyBeSubscribedTo: channels,
                    });
                    if (channels && channels.length) {
                        const channelsNotYetSubscribed = channels.filter(
                            channel => !channel.includes('pnpres') && !subscribedChannels.includes(channel),
                        );
                        if (channelsNotYetSubscribed.length) {
                            console.info('Subscribing to channels', channels);
                            pubnub?.subscribe({ channels: channels, withPresence: true });
                        }
                    }
                });
            }
        }, 10000);

        return () => clearInterval(x);
    }, [channels, user]);

    useEffect(() => {
        if (oldChannels && oldChannels.length) {
            console.info('Un-subscribing from channels', oldChannels);
            pubnub?.unsubscribe({ channels: oldChannels });
        }
    }, [oldChannels]);

    const changeUserWrapper = (user: Users.User) => {
        return Notifier.setActiveUser({ user, setActiveUser: changeUser });
    };

    const setActiveClientWrapper = (client: (Required<Pick<Clients.Client, '_id'>> & Clients.Client) | undefined) => {
        if (client) return Notifier.setActiveClient({ client, setActiveClient });
    };

    const setActiveEventWrapper = (event: (Required<Pick<Events.Event, '_id'>> & Events.Event) | undefined) => {
        if (event) return Notifier.setActiveEvent({ event, setActiveEvent });
    };

    const refreshActiveEvent = async (clientId: string, eventId: string) => {
        const event = await apiRequester.getEvent(clientId, eventId);
        setActiveEvent(event);
        return event;
    };

    const setActiveBoothWrapper = (booth: (Required<Pick<Booths.Booth, '_id'>> & Booths.Booth) | undefined) => {
        if (booth) return Notifier.setActiveBooth({ booth, setActiveBooth });
    };

    const refreshClients = async () => {
        const clients = await apiRequester.getClients();
        setClients(clients);
        return clients;
    };

    const refreshEvents = async (clientId: string) => {
        const events = await apiRequester.getEvents(clientId);
        setEvents(events);
        return events;
    };

    const refreshClientUsers = async (clientId: string, eventId?: string, boothId?: string) => {
        const users = await apiRequester.getOperators(clientId, eventId, boothId);
        const usersWithFullName = users.map(user => {
            return { ...user, fullName: user.firstName + ' ' + user.lastName };
        });
        setClientUsers(usersWithFullName.reverse());
        return usersWithFullName;
    };

    const refreshBooths = async (clientId: string, eventId: string) => {
        const booths = await apiRequester.getBooths(clientId, eventId);
        setBooths(booths);
        return booths;
    };

    const refreshEventModules = async (clientId: string, eventId: string) => {
        const eventModules = await apiRequester.getEventModules(clientId, eventId);
        setEventModules(eventModules);
        return eventModules;
    };

    const refreshBoothModules = async (clientId: string, eventId: string, boothId: string) => {
        const boothModules = await apiRequester.getBoothModules(clientId, eventId, boothId);
        setBoothModules(boothModules);
        return boothModules;
    };

    const unsubscribeAllFromNotifier = () => {
        Notifier.unsubscribeAll();
    };

    const refreshUserList = async (userIds: string[]) => {
        console.log('Refreshing user list');
        const existingIds = Object.keys(userList);
        const newIds = userIds.filter(userId => !existingIds.includes(userId));
        const newUserList: { [id: string]: Users.User } = {};

        let filter = ``;
        newIds.map((id, index) => {
            filter += `id=="${id}" ${index < newIds.length - 1 ? '||' : ''}`;
        });

        if (filter) {
            const allUsers = await pubnub?.objects.getAllUUIDMetadata({
                filter: filter,
                include: { customFields: true, totalCount: true },
                limit: 100,
            });

            allUsers?.data.map(userMeta => {
                const userId = userMeta.id;
                newUserList[userId] = {
                    _id: userId,
                    firstName: userMeta?.name || undefined,
                    roles: [userMeta?.custom?.role ? (userMeta?.custom?.role as Users.Role) : 'visitor'],
                    profilePicture: { link: userMeta?.custom?.profilePictureLink as string },
                };
            });

            const updatedUserList = { ...userList, ...newUserList };
            setUserList(updatedUserList);
            return updatedUserList;
        } else return userList;
    };

    const globalContext: GlobalContextType = {
        user,
        changeUser,
        token,
        changeToken,
        clients,
        activeClient,
        setClients,
        setActiveClient,
        refreshClients,
        events,
        activeEvent,
        setEvents,
        setActiveEvent,
        refreshEvents,
        clientUsers,
        setClientUsers,
        refreshClientUsers,
        booths,
        activeBooth,
        setBooths,
        setActiveBooth,
        refreshBooths,
        eventModules,
        setEventModules,
        refreshEventModules,
        boothModules,
        setBoothModules,
        refreshBoothModules,
        unsubscribeAllFromNotifier,
        userList,
        refreshUserList,
        refreshActiveEvent,
        channels,
        activeVideoRoom,
        setActiveVideoRoom,
        pubnub,
        setPubnub,
        setChannels,
        oldChannels,
        setOldChannels,
    };

    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
