export const beautifyName = (name: string) => {
    let modifiedName = name.replace('-', ' ');
    modifiedName = modifiedName.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return modifiedName;
};

export const getResourceId = (resource: string | ({ _id: string } & any)): string | undefined => {
    return typeof resource === 'string' ? resource : resource?._id;
};
